import Modal from 'react-bootstrap/Modal'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import TSConstants from '../../utils/TSConstants'
import WheelButton from '../cta/WheelButton'
import { useDispatch, useSelector } from 'react-redux'
import { async_resend_gift_email } from '../../redux/actions/lottery'
import { tt } from '../../i18n'
import { setMarketingModalState } from '../../redux/actions/app'
import { userSelector } from '../../redux/selectors/app'
import { helpModalErrorSelector } from '../../redux/selectors/lottery'
import { configSelector } from '../../redux/selectors/config'
import { aspect_ratio } from '../../utils/aspect_ratio'
import { VERSION } from '../../analytics/WheelAnalyticsManager'

interface Props {
  show: boolean
  onHide: () => void
  isIOS: boolean
  displayContactFormOnHelp?: boolean
}

const HelpModal: FunctionComponent<Props> = (props: Props) => {
  const [imageToDisplay, setImageToDisplay] = useState<string | null>(null)
  const [resendEmail, setResendEmail] = useState<boolean>(false)
  const [email1, setEmail1] = useState<string>('')
  const [email2, setEmail2] = useState<string>('')
  const [dateAndTime, setDateAndTime] = useState<string>('')
  const [gift, setGift] = useState<string>('')
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [placeName, setPlaceName] = useState<string>('')
  const [optin, setOptin] = useState(false)

  const dispatch = useDispatch()

  const user = useSelector(userSelector)
  const helpModalError = useSelector(helpModalErrorSelector)
  const siteConfig = useSelector(configSelector)

  const { show, displayContactFormOnHelp, onHide } = props

  const showContactFormForIndividuals = () => {
    dispatch(setMarketingModalState(true, false, true))
    props.onHide()
  }

  const resendGiftEmail = () => {
    if (!email1) {
      alert('Vous devez indiquer votre adresse email')
      return
    }

    if (!placeName) {
      alert('Vous devez indiquer le nom du lieu')
      return
    }

    if (!phoneNumber) {
      alert('Vous devez indiquer votre numéro de téléphone')
      return
    }

    if (!optin) {
      alert(
        'Nous ne pouvons pas vous re-contacter si vous ne nous autorisez pas à traiter vos données personnelles.'
      )
      return
    }

    // this.props.async_resend_gift_email(this.props.siteConfig, this.state.email)
    dispatch(
      async_resend_gift_email(
        siteConfig,
        dateAndTime,
        gift,
        phoneNumber,
        email1,
        email2,
        placeName
      )
    )
  }

  const displayImage = (img: string) => {
    setImageToDisplay(img)
  }

  const renderItem = (
    text: string,
    imgAndroid: string,
    imgIOS: string,
    href?: string,
    onClick?: () => void
  ) => {
    let clickHandler: (() => void) | undefined = () =>
      props.isIOS ? displayImage(imgIOS) : displayImage(imgAndroid)

    if (href && href.length > 0) {
      clickHandler = undefined
    }

    if (onClick) {
      clickHandler = onClick
    }

    return (
      <div className="row w-margin-bottom-half">
        <div className="col-12">
          <div className="w-tutorial-choice" onClick={clickHandler}>
            {!!href && (
              <a
                className="tuto-a"
                href={href}
                target="_blank"
                rel="noreferrer"
              >
                {text}
              </a>
            )}
            {!href && <span>{text}</span>}
          </div>
        </div>
      </div>
    )
  }

  const hide = useCallback(() => {
    setImageToDisplay(null)
    setResendEmail(false)
    onHide()
  }, [onHide])

  // ON UPDATES

  useEffect(() => {
    if (user && user.paymentEmail && !email1) {
      setEmail1(user.paymentEmail)
    }
  }, [user, email1])

  useEffect(() => {
    if (show && displayContactFormOnHelp) {
      onHide()
      setMarketingModalState(true, false, true)
    }
  }, [show, displayContactFormOnHelp, onHide, setMarketingModalState])

  useEffect(() => {
    switch (helpModalError) {
      case -1:
        break

      case 0:
        alert(tt('your_request_was_sent'))
        hide()
        break

      case 1:
        alert(tt('error'))
        break

      case 2:
        alert(tt('technical_error'))
        break
    }
  }, [helpModalError, hide])

  return (
    <Modal
      show={props.show}
      onHide={hide}
      centered={true}
      size="lg"
      className="ts-help-modal w-modal-couponsupportrequest"
    >
      <Modal.Body style={{ overflowY: resendEmail ? 'scroll' : 'hidden' }}>
        <i className="fas fa-times modal-close" onClick={hide} />
        <div className="">
          {resendEmail && (
            <>
              <div className="row w-margin-bottom-half">
                <div className="col-12">
                  <h2 className="h2">Je n'ai pas reçu mon coupon cadeau</h2>
                </div>
              </div>
              <div className="row w-margin-bottom-half">
                <div className="col-12">
                  <p>
                    Vous avez peut-être fait une erreur dans votre adresse
                    email.
                  </p>
                </div>
              </div>
              <div className="row w-margin-bottom-half">
                <div className="col-12">
                  <p>Vérifiez-en l'orthographe :</p>
                </div>
              </div>
              <div className="row w-margin-bottom-half">
                <div className="col-12">
                  <input
                    className="form-control"
                    id="email-input-1"
                    placeholder="Adresse email principale"
                    type="email"
                    inputMode="email"
                    value={email1}
                    onChange={(e) => setEmail1(e.target.value)}
                  />
                </div>
              </div>
              <div className="row w-margin-bottom-half">
                <div className="col-12">
                  <input
                    className="form-control"
                    id="email-input-2"
                    placeholder="Adresse email secondaire"
                    type="email"
                    inputMode="email"
                    value={email2}
                    onChange={(e) => setEmail2(e.target.value)}
                  />
                </div>
              </div>
              <div className="row w-margin-bottom-half">
                <div className="col-12">
                  <p>Dans quel établissement avez-vous joué ?</p>
                </div>
              </div>
              <div className="row w-margin-bottom-half">
                <div className="col-12">
                  <input
                    className="form-control"
                    id="date-and-time-input"
                    type="text"
                    placeholder="Nom du lieu (facultatif)"
                    value={placeName}
                    onChange={(e) => setPlaceName(e.target.value)}
                  />
                </div>
              </div>
              <div className="row w-margin-bottom-half">
                <div className="col-12">
                  <p>Quand est-ce que vous avez joué ?</p>
                </div>
              </div>
              <div className="row w-margin-bottom-half">
                <div className="col-12">
                  <input
                    className="form-control"
                    id="date-and-time-input"
                    type="text"
                    placeholder="Date et heure approximatives"
                    value={dateAndTime}
                    onChange={(e) => setDateAndTime(e.target.value)}
                  />
                </div>
              </div>
              <div className="row w-margin-bottom-half">
                <div className="col-12">
                  <p>Quel est votre numéro de téléphone ?</p>
                </div>
              </div>
              <div className="row w-margin-bottom-half">
                <div className="col-12">
                  <input
                    className="form-control"
                    id="phone-number-input"
                    type="text"
                    inputMode="tel"
                    placeholder="06XXXXXXXX"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </div>
              </div>
              <div className="row w-margin-bottom-half">
                <div className="col-12">
                  <p>Quel cadeau avez-vous gagné ?</p>
                </div>
              </div>
              <div className="row w-margin-bottom-half">
                <div className="col-12">
                  <input
                    className="form-control"
                    id="gift-input"
                    type="text"
                    value={gift}
                    placeholder="Facultatif"
                    onChange={(e) => setGift(e.target.value)}
                  />
                </div>
              </div>
              <div className="row w-margin-bottom-half">
                <div className="col-12">
                  <p>
                    <div
                      className="form-group form-check"
                      onClick={() => setOptin(!optin)}
                    >
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="optin-check"
                        required={true}
                        checked={optin}
                      />
                      <label className="form-check-label" htmlFor="optin-check">
                        {tt('optin_coupon_support')}
                      </label>
                    </div>
                  </p>
                </div>
              </div>

              <div className="row w-margin-bottom-half">
                <div className="col-12">
                  <WheelButton
                    id="help-cta"
                    text="Renvoyer à cet email"
                    onClick={resendGiftEmail}
                  />
                </div>
              </div>
              <div id="dummy-div" className="dummy-div"></div>
            </>
          )}

          {!resendEmail && (
            <>
              <div className="row w-margin-bottom-half">
                <div className="col-12">
                  <h2 className="h2">{tt('help')}</h2>
                </div>
              </div>

              {!imageToDisplay && (
                <div className="row w-margin-bottom">
                  <div className="col-12">
                    {renderItem(
                      tt('help_1'),
                      'img/tutos/android_help_google.png',
                      'img/tutos/ios_help_google.png'
                    )}
                    {renderItem(
                      tt('help_2'),
                      'img/tutos/android_help_insta.png',
                      'img/tutos/ios_help_insta.png'
                    )}
                    {renderItem(tt('help_3'), '', '', undefined, () =>
                      setResendEmail(true)
                    )}
                    {renderItem(
                      tt('help_4'),
                      '',
                      '',
                      undefined,
                      showContactFormForIndividuals
                    )}
                  </div>
                </div>
              )}
              {!!imageToDisplay && (
                <div className="row w-margin-bottom">
                  <div className="col-12">
                    <img
                      className="twic"
                      src={TSConstants.PLACEHOLDER_URL}
                      data-src={`image:/cdm/${imageToDisplay}`}
                      style={{ width: '100%' }}
                    />
                  </div>
                </div>
              )}

              {!imageToDisplay && (
                <div className="row w-margin-bottom">
                  <div className="col-12">
                    <p>
                      <small>
                        <u>Infos techniques</u>
                        <br />
                        Ratio = {aspect_ratio}, écran = {window.screen.width}/
                        {window.screen.height}, viewport = {window.innerWidth}/
                        {window.innerHeight}
                        <br />
                        Version: {VERSION}
                      </small>
                    </p>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default HelpModal
