import React, { ReactNode } from 'react'
import { TSUtils } from '../../utils/TSUtils'
import LotteryConfigurationResponse from '@tootsweet/model/lottery/LotteryConfigurationResponse'
import { MonthlyLotteryDrawJavaDTO} from "kadow-model-monthly-lottery"

export default function renderTokens(
  lotteryConfig: LotteryConfigurationResponse | undefined,
  draw: MonthlyLotteryDrawJavaDTO | undefined,
  tokenImgWidth: number,
  width: number,
  height: number
) {
  if (!lotteryConfig || !draw || !draw.nTokens) {
    return null
  }

  const res: ReactNode[] = []
  const positions: { top: number; left: number }[] = []
  const padding = 30

  for (let idx = 0; idx < draw.nTokens; idx++) {
    let top = Math.trunc(
      TSUtils.getRandomIntInRange(padding, height - tokenImgWidth - padding)
    )
    let left = Math.trunc(
      TSUtils.getRandomIntInRange(padding, width - tokenImgWidth - padding)
    )

    let overlap = false

    do {
      positions.forEach((token) => {
        const dX = token.top - top
        const dY = token.left - left
        const dist = Math.sqrt(dX * dX + dY * dY)

        if (dist < 1.2 * tokenImgWidth) {
          overlap = true
          top = Math.trunc(
            TSUtils.getRandomIntInRange(
              padding,
              height - tokenImgWidth - padding
            )
          )
          left = Math.trunc(
            TSUtils.getRandomIntInRange(
              padding,
              width - tokenImgWidth - padding
            )
          )
        } else {
          overlap = false
        }
      })
    } while (overlap)

    positions.push({ top, left })
  }

  let dimW = tokenImgWidth
  let dimH = tokenImgWidth

  if (lotteryConfig?.mlTokenW && lotteryConfig?.mlTokenH) {
    dimW = lotteryConfig?.mlTokenW
    dimH = lotteryConfig?.mlTokenH
  }

  positions.forEach((pos, idx) => {
    res.push(
      <img
        key={`tkn-${idx}`}
        className="twic k-glm-token"
        data-src={`image:/cdm/kadow/glm/${
          lotteryConfig?.mlToken || 'token.png'
        }`}
        data-src-transform="contain=WxH"
        style={{
          width: dimW,
          height: dimH,
          top: pos.top,
          left: pos.left,
          position: 'absolute',
          transform: `rotate(${TSUtils.getRandomIntInRange(0, 180)}deg)`,
        }}
      />
    )
  })

  return res
}
