import LotteryConfigurationResponse, {
  WheelItem,
} from '@tootsweet/model/lottery/LotteryConfigurationResponse'
import { TSUtils } from '../../utils/TSUtils'
import { MonthlyLotteryDrawJavaDTO} from "kadow-model-monthly-lottery"
import { GiftMachineImageParameters } from './giftMachineImageParameters'

const MAX_WIDTH_PC = 0.7
const INTERLINE_PX = 10
const OFFSET_WIDTH_RATIO = 0.88

export default function generateGiftMachineImage(
  width: number,
  height: number,
  itemHeight: number,
  textHeightFactor: number,
  mlDraw: MonthlyLotteryDrawJavaDTO | null,
  config?: LotteryConfigurationResponse,
  textLeft?: number
): GiftMachineImageParameters | null {
  if (!config || !config.wheelItems || config.wheelItems.length === 0) {
    return null
  }

  const textHeight = Math.trunc(itemHeight / textHeightFactor)
  const imgHeight = itemHeight * config.wheelItems.length
  const FONT = `bold ${textHeight}px "Brown", sans-serif`
  const canvas = document.createElement('canvas')

  if (!canvas) {
    return null
  }

  canvas.width = width
  canvas.height = imgHeight
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    return null
  }

  const grad = ctx.createLinearGradient(0, 0, 0, imgHeight)
  grad.addColorStop(0, '#f2c0ff')
  grad.addColorStop(0.5, '#f498ff')
  grad.addColorStop(1, '#f2c0ff')
  ctx.fillStyle = grad
  ctx.fillRect(0, 0, width, imgHeight)
  ctx.font = FONT
  let y = 0

  config.wheelItems.forEach((item, idx) => {
    const label = item.e ? item.e : item.label

    // for debug purposes
    // ctx.fillStyle = chroma.random().hex()
    // ctx.fillRect(0, y, width, itemHeight)

    if (item.isGC && mlDraw?.nTokens) {
      const tokenDim = textHeight * 1.7
      const tokenOffset = tokenDim / 2
      const tokensWidth =
        mlDraw?.nTokens * tokenDim -
        (mlDraw?.nTokens - 1) * (tokenDim - tokenOffset)
      const img = document.getElementById('img-gc') as HTMLImageElement
      let offsetX = ((width - tokensWidth) * OFFSET_WIDTH_RATIO) / 2

      for (let i = 0; i < mlDraw.nTokens; i++) {
        const offsetY = y + (itemHeight - tokenDim) / 2
        ctx.drawImage(img, offsetX, offsetY, tokenDim, tokenDim)
        offsetX += tokenOffset
      }
    } else {
      let metrics = ctx.measureText(label)
      const actualHeight = metrics.fontBoundingBoxAscent
      let offsetY = Math.trunc(
        y + actualHeight + (itemHeight - actualHeight) / 2
      )
      const maxWidth = Math.trunc(width * MAX_WIDTH_PC)
      const isTooWide = metrics.width > maxWidth
      let offsetX = Math.trunc(
        ((width - metrics.width) * OFFSET_WIDTH_RATIO) / 2
      )

      ctx.fillStyle = 'black'

      if (isTooWide) {
        const splitIdx = determineMostCentralSpace(item)
        const str1 = item.label.substring(0, splitIdx - 1).trim()
        const str2 = item.label.substring(splitIdx, item.label.length).trim()

        metrics = ctx.measureText(str1)
        offsetX = Math.trunc(((width - metrics.width) * OFFSET_WIDTH_RATIO) / 2)
        offsetY = Math.trunc(
          y + actualHeight + (itemHeight - actualHeight * 2 - INTERLINE_PX) / 2
        )

        ctx.fillText(
          TSUtils.capitalizeFirstLetter(str1),
          offsetX + (textLeft || 0),
          offsetY
        )

        metrics = ctx.measureText(str2)
        offsetX = Math.trunc(((width - metrics.width) * OFFSET_WIDTH_RATIO) / 2)

        ctx.fillText(
          str2,
          offsetX + (textLeft || 0),
          offsetY + actualHeight + INTERLINE_PX
        )
      } else {
        ctx.fillText(
          TSUtils.capitalizeFirstLetter(label),
          offsetX + (textLeft || 0),
          offsetY
        )
      }
    }

    y += itemHeight
  })

  return {
    width,
    img: canvas.toDataURL('image/png', 1),
    imgHeight,
    nItems: config.wheelItems.length,
  }
}

function determineMostCentralSpace(item: WheelItem) {
  const spacesIdx = []
  /**
   * Build list of spaces indices
   */
  for (let idx = 0; idx < item.label.length; idx++) {
    const char = item.label[idx]
    if (char === ' ') {
      spacesIdx.push(idx)
    }
  }
  /**
   * Determine the index of the space char
   * that is closest to the middle of the string
   */
  const middle = Math.trunc(item.label.length / 2)
  let minDist = Number.MAX_SAFE_INTEGER
  let minDistIdx = -1
  spacesIdx.forEach((idx) => {
    const dist = Math.abs(idx - middle)
    if (dist < minDist) {
      minDist = dist
      minDistIdx = idx
    }
  })
  if (minDistIdx) {
    return middle
  }
  return minDistIdx
}
